export default function (
  routeName = '',
  activeStudyId: string | null = null,
  isDCToolStudy = false
) {
  const isNotStudyRoute = routeName !== 'study'
  /**
   * If the activeStudyId is the onboarding study,
   * we don't want to redirect, since the user needs to access the onboarding journey
   *
   * If the study is a DC Tool study and we are on the DC Tool page,
   * we don't want to redirect, since the user needs to access the DC Tool
   * Same for 404 as we intentionally take them there if the user is trying to access
   * a DC Tool page for a Batch that they do not have access to
   */
  const hasActiveStudyId =
    activeStudyId &&
    activeStudyId !== 'onboarding' &&
    (!isDCToolStudy ||
      (isDCToolStudy && routeName !== 'dc_tool' && routeName !== 'error.404'))
  const isNotMessagesRoute = !routeName.match(/messages/)
  // If the route is submissions.complete, we don't want to redirect, since the user needs to complete the submission
  const isNotSubmissionsCompleteRoute = routeName !== 'submissions.complete'

  return !!(
    isNotStudyRoute &&
    hasActiveStudyId &&
    isNotMessagesRoute &&
    isNotSubmissionsCompleteRoute
  )
}
