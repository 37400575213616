import pusher from '@/integrations/pusher'

const pusherEvents = {
  PROCESSED: 'PROCESSED',
}

/**
 * Takes a dataset ID and subscribes to its pusher channel.
 * Returns a promise that resolves when the PROCESSED event is received from Pusher,
 * or unsubscribes and rejects if no event is received after 5 minutes.
 *
 * @param datasetId - The dataset to receive updates for.
 * @returns Promise<void>
 */
export const getDatasetPusherSubscription = (
  datasetId: string
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const channel = pusher.subscribe(datasetId)

    const timeoutSubscriber = () => {
      pusher.unsubscribe(datasetId)
      reject(new Error('Dataset processing timeout'))
    }

    const timeoutSubscription = setTimeout(timeoutSubscriber, 5 * 60 * 1000)

    channel.bind(pusherEvents.PROCESSED, () => {
      clearTimeout(timeoutSubscription)
      pusher.unsubscribe(datasetId)
      resolve()
    })
  })
}
