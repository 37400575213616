// a place for any active feature flag strings

/*----- Seagulls 𓅯 -----*/

export const FLAG_MUA_PROJECT_LEVEL_ACCESS = 'MUA-278_Temp_Project-Level-Access'

export const FLAG_HI_REP_SAMPLE_US_ENGLISH_AGE_POLITICAL_AFFILIATION =
  'HI-1209-us-political-rep-sample-without-ethnicity'

export const FLAG_HI_REP_SAMPLE_US_ENGLISH_AGE_POLITICAL_AFFILIATION_ETHNICITY =
  'HI-1209-US-political-rep-sample'

export const FLAG_SEA_CUSTOM_QUOTA_SAMPLES = 'SEA-1422-CUSTOM-QUOTA-SAMPLES'

export const FLAG_SEA_CUSTOM_QUOTA_SAMPLES_DEFAULT_APPLY_SEX_BALANCED_QUOTA =
  'SEA-1422-CUSTOM-QUOTA-SAMPLES-DEFAULT-APPLY-SEX-BALANCED-QUOTA'

export const FLAG_SEA_REP_SAMPLE_UK_ENGLISH_AGE_POLITICAL_AFFILIATION =
  'SEA-1590-UK-POLITICAL-REP-SAMPLE-WITHOUT-ETHNICITY'

export const FLAG_SEA_REP_SAMPLE_UK_ENGLISH_AGE_POLITICAL_AFFILIATION_ETHNICITY =
  'SEA-1590-UK-POLITICAL-REP-SAMPLE-WITH-ETHNICITY'

export const FLAG_SEA_COLLAPSED_NAV = 'SEA-1937-collapsed-nav'

export const FLAG_SEA_SUMMARY_SIDE_PANEL = 'SEA-1829-summary-side-panel'

export const FLAG_SEA_ENABLE_THROUGHPUT_OPTIMISATION =
  'SEA-2053-ENABLE-THROUGHPUT-OPTIMISATION'

export const FLAG_SEA_VIRTUALIZE_PGS = 'SEA-2212-VIRTUALIZE-PGS'

export const FLAG_SEA_BULK_MESSAGE_PG =
  'SEA-2292-bulk-message-participant-group'

export const FLAG_SEA_WORKSPACE_MESSAGING_NAVBAR =
  'SEA-2310-workspace-messaging-navbar'

/*----- Ex-EARS + SECT -----*/
export const FLAG_INC_AI_WORKSPACE_LANGUAGE = 'INC-AI-Workspace-Language'
export const FLAG_INC_MESSAGES_MOBILE_IMPROVEMENTS =
  'messages-mobile-improvements'
export const FLAG_EARS_MESSAGES_IN_MARKDOWN = 'EARS-messages-in-markdown-v2'
export const FLAG_EARS_DELETE_WORKSPACE = 'EARS-Delete-Workspace'
export const FLAG_EARS_658_HIDE_PAYMENT_TIMING_MESSAGE_CATEGORY =
  'EARS-658-hide-payment-timing-message-category-for-participants'

export const FLAG_SECT_381_MFA_PHASE_1 = 'SECT-381-MFA-PHASE-1'
export const FLAG_SECT_381_MFA_PHASE_1_PARTICIPANT_REGISTRATION =
  'SECT-381-MFA-PHASE-1-PARTICIPANT-REGISTRATION'
export const FLAG_SECT_381_MFA_PHASE_1_RESEARCHER_REGISTRATION =
  'SECT-381-MFA-PHASE-1-RESEARCHER-REGISTRATION'
export const FLAG_SECT_528_ADDITIONAL_ADA_DATA = 'SECT-528-additional-ada-data'
export const FLAG_SECT_545_MFA_RECOVERY = 'SECT-545-MFA-RECOVERY'

/*----- Payments team -----*/

export const FLAG_SCAL_REQUEST_3DS_CHALLENGE =
  'SCAL-541_Perm_Request-3DS-challenge'

export const FLAG_PAY_SKIP_3DS_VERIFICATION =
  'PAY-906_Temp_Skip-3DS-verification'

/*----- Eng Support -----*/

export const FLAG_ES_DISABLE_CASHOUTS = 'ES-477_Temp_Disable-cashouts'

export const FLAG_ES_MAINTENANCE_MODE = 'ES-prolific-app-maintenance-mode'

/*----- PI team -----*/
export const FLAG_PSG_509_PII = 'PSG-509-pii'
export const FLAG_PSG_870_NEW_AUDIENCE_SELECTION =
  'PSG_870_NEW_AUDIENCE_SELECTION'
export const FLAG_PI_PLAYBACK = 'PI-playback'

/*----- IB team -----*/
export const FLAG_IB_MONOCLE_ENABLED = 'IAN-130-Monocle-Enabled'
export const FLAG_IB_PEC_ERRORS_MODAL = 'ian-319-pec-errors-modal'
export const FLAG_IB_ONFIDO_STUDIO = 'IAN-464-Onfido-Studio'
export const FLAG_IB_LLM_MONITORING_PACKAGE = 'IAN-684-LLM-Monitoring-Package'

/*----- DCT team -----*/
export const FLAG_INC_HIDE_DATA_COLLECTION_OPTIONS =
  'INC-Hide-Data-Collection-Options'
export const FLAG_INC_DATA_COLLECTION_CREDENTIALS =
  'INC-Data-collection-credentials'
export const FLAG_DCP_TASKFLOW = 'DCP-TARS'
export const FLAG_DCT_AI_TASK_BUILDER = 'DCT-Data-Collection-Tool'
export const FLAG_DCT_AI_TASK_BUILDER_RESEARCHER_UI =
  'DCT-Data-Collection-Tool-Researcher-UI'

/*----- SSG team -----*/
export const FLAG_EARS_466_FREE_TRIAL_STUDY = 'EARS-446-free-trial-study'
export const FLAG_SSG_ONBOARDING_STEPS = 'SSG-162-onboarding-steps'
export const FLAG_SSG_MARKETING_SOFT_OPT_IN = 'SSG-marketing-soft-opt-in'
export const FLAG_SSG_CLEARBIT_INTEGRATION = 'SSG-clearbit-integration'
export const FLAG_SSG_EXPLORE_CARD_EXPERIMENT =
  'SSG-268-explore-card-experiment'
export const FLAG_SSG_SCREENING_TABS = 'SSG-280-audience-screening-tabs'
export const FLAG_SSG_SCREENING_TABS_COUNTS =
  'SSG-280-audience-screening-tabs-counts'
export const FLAG_SSG_PROJECT_STATUS = 'SSG-356-project-status'
export const FLAG_SSG_PROJECT_TITLE_AUTOSAVE_TIMEOUT =
  'SSG-360-project-title-autosave-timeout'
export const FLAG_SSG_PROJECT_SELECTION = 'SSG-374-project-selection'
export const FLAG_SSG_RESEARCHER_MARKETING_EMAIL_PREFERENCES =
  'SSG-155-researcher-marketing-email-preferences'
export const FLAG_SSG_PIN_PROJECTS = 'SSG-391-pin-projects'
export const FLAG_SSG_HIDE_PROJECTS = 'SSG-392-hide-projects'
