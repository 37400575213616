import { USER_STATUSES } from '@/api/types/user'
import { featureIsEnabled } from '@/integrations/launchDarkly'
import { FLAG_SECT_528_ADDITIONAL_ADA_DATA } from '@/integrations/launchDarkly/active-flags'
import store from '@/store'

export const setAdaMetaFields = (
  additionalMetaFields?: Record<string, unknown>
): void => {
  if (featureIsEnabled(FLAG_SECT_528_ADDITIONAL_ADA_DATA)) {
    let baseFields = {}

    const apiUser = store.getters['auth/user']

    if (apiUser && apiUser.status !== USER_STATUSES.IN_MFA_RECOVERY) {
      baseFields = {
        user_type: apiUser.user_type,
        prolific_id: apiUser.id,
        name: apiUser.first_name,
        email: apiUser.email,
      }
    }

    if (window.adaEmbed && window.adaSettings) {
      window.adaSettings = {
        metaFields: {
          ...baseFields,
          ...additionalMetaFields,
        },
      }

      window.adaEmbed.setMetaFields({
        ...baseFields,
        ...additionalMetaFields,
      })
    }
  }
  return
}

export const clearAdaMetaData = (): void => {
  if (featureIsEnabled(FLAG_SECT_528_ADDITIONAL_ADA_DATA)) {
    window.adaEmbed.deleteHistory()
    window.adaEmbed.reset()
  }
  return
}
