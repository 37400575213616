import type { Module } from 'vuex'
import { endpoints, http } from '@/api'
import type { Playback } from '@/api/types'

export type State = {
  'playback-2024': Playback | null
}

const SET_PLAYBACK_2024 = 'SET_PLAYBACK_2024'

export default {
  namespaced: true,

  state: {
    'playback-2024': null,
  } as State,

  mutations: {
    [SET_PLAYBACK_2024](state: State, playbackData: Playback) {
      state['playback-2024'] = playbackData
    },
  },

  actions: {
    async fetchPlayback2024({ commit }) {
      try {
        const playback = await http.get<Playback>(
          endpoints.PARTICIPANT_PLAYBACK(2024)
        )

        commit(SET_PLAYBACK_2024, playback)
      } catch (error) {
        Promise.reject(error)
      }
    },
  },

  getters: {
    playback2024: (state: State) => state['playback-2024'],
  },
} satisfies Module<State, unknown>
