import type { Study } from '@/api/types'
import {
  DATA_COLLECTION_EXTERNAL,
  DATA_COLLECTION_SURVEY_BUILDER,
  DATA_COLLECTION_TASKFLOW,
  SURVEY_BUILDER_APP_KEY,
  DATA_COLLECTION_AI_TASK_BUILDER,
} from '@/constants'
import { checkStudyIsDcToolStudy } from '@/utils/check-study-is-dctool-study'
import { isAccessDetailCollectionUrl } from '@/utils/isAccessDetailCollectionUrl'

// TODO: we don't persist study.data_collection_method for all types of data collection methods
// at the moment, so we need to infer it from the external_app and external_study_url.
// This should be addressed by https://prolific.atlassian.net/browse/SEA-2233

export function inferDataCollectionMethod(
  study: Study
): Study['data_collection_method'] {
  if (checkStudyIsDcToolStudy(study)) {
    return DATA_COLLECTION_AI_TASK_BUILDER
  }

  if (study.external_app === SURVEY_BUILDER_APP_KEY) {
    // else if the external app is the survey builder, we know it's a survey
    return DATA_COLLECTION_SURVEY_BUILDER
  }

  if (isAccessDetailCollectionUrl(study.external_study_url)) {
    // else if the external study url matches a taskflow url, we know it's taskflow
    return DATA_COLLECTION_TASKFLOW
  }

  // else default to external
  return DATA_COLLECTION_EXTERNAL
}
