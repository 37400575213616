import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import { FLAG_PI_PLAYBACK } from '@/integrations/launchDarkly/active-flags'
import store from '@/store'

export default [
  {
    path: '/playback-2024',
    name: 'playback_2024',
    component: () => import('@/views/participant/playback/LoadingPlayback.vue'),
    meta: {
      requiresLDFlag: FLAG_PI_PLAYBACK,
    },
    async beforeEnter(
      _: RouteLocationNormalized,
      __: RouteLocationNormalized,
      next: NavigationGuardNext
    ) {
      const playbackAvailable = store.getters['auth/isPlaybackAvailable']

      // If playback available, redirect to first page
      if (playbackAvailable) {
        return next({ name: 'playback_2024.page_1' })
      }

      // Otherwise, let user access loading page and trigger generation
      await store.dispatch('participant/playback/fetchPlayback2024')
      next()
    },
  },
  {
    path: '/playback-2024/page-1',
    name: 'playback_2024.page_1',
    component: () =>
      import('@/views/participant/playback/pages/PlaybackPage1.vue'),
    meta: {
      requiresLDFlag: FLAG_PI_PLAYBACK,
    },
    async beforeEnter(
      _: RouteLocationNormalized,
      __: RouteLocationNormalized,
      next: NavigationGuardNext
    ) {
      const playback = store.state.participant.playback['playback-2024']

      if (!playback || !Object.keys(playback).length) {
        await store.dispatch('participant/playback/fetchPlayback2024')
      }

      next()
    },
  },
  {
    path: '/playback-2024/page-2',
    name: 'playback_2024.page_2',
    component: () =>
      import('@/views/participant/playback/pages/PlaybackPage2.vue'),
    meta: {
      requiresLDFlag: FLAG_PI_PLAYBACK,
    },
    async beforeEnter(
      _: RouteLocationNormalized,
      __: RouteLocationNormalized,
      next: NavigationGuardNext
    ) {
      const playback = store.state.participant.playback['playback-2024']

      if (!playback || !Object.keys(playback).length) {
        await store.dispatch('participant/playback/fetchPlayback2024')
      }

      next()
    },
  },
  {
    path: '/playback-2024/page-3',
    name: 'playback_2024.page_3',
    component: () =>
      import('@/views/participant/playback/pages/PlaybackPage3.vue'),
    meta: {
      requiresLDFlag: FLAG_PI_PLAYBACK,
    },
    async beforeEnter(
      _: RouteLocationNormalized,
      __: RouteLocationNormalized,
      next: NavigationGuardNext
    ) {
      const playback = store.state.participant.playback['playback-2024']

      if (!playback || !Object.keys(playback).length) {
        await store.dispatch('participant/playback/fetchPlayback2024')
      }

      next()
    },
  },
  {
    path: '/playback-2024/page-4',
    name: 'playback_2024.page_4',
    component: () =>
      import('@/views/participant/playback/pages/PlaybackPage4.vue'),
    meta: {
      requiresLDFlag: FLAG_PI_PLAYBACK,
    },
    async beforeEnter(
      _: RouteLocationNormalized,
      __: RouteLocationNormalized,
      next: NavigationGuardNext
    ) {
      const playback = store.state.participant.playback['playback-2024']

      if (!playback || !Object.keys(playback).length) {
        await store.dispatch('participant/playback/fetchPlayback2024')
      }

      next()
    },
  },
  {
    path: '/playback-2024/page-5',
    name: 'playback_2024.page_5',
    component: () =>
      import('@/views/participant/playback/pages/PlaybackPage5.vue'),
    meta: {
      requiresLDFlag: FLAG_PI_PLAYBACK,
    },
    async beforeEnter(
      _: RouteLocationNormalized,
      __: RouteLocationNormalized,
      next: NavigationGuardNext
    ) {
      const playback = store.state.participant.playback['playback-2024']

      if (!playback || !Object.keys(playback).length) {
        await store.dispatch('participant/playback/fetchPlayback2024')
      }

      next()
    },
  },
]
