import { isAxiosError } from 'axios'
import type { Dispatch } from 'vuex'
import { endpoints, http } from '@/api'
import type { Dataset } from '@/api/types'
import { getDatasetPusherSubscription } from './get-dataset-pusher-subscription'

/**
 * Handles the saving of a draft dataset with the following strategy:
 *
 * If there no datasetId in draft state:
 *    If there is a dataset file:
 *      - create dataset and upload to it
 *      - return dataset Id and promise that resolves when pusher PROCESSED event is received
 *    Else return error as batch / dataset creation is not viable
 *
 * If there is a datasetId in draft state:
 *    Retrieve dataset and handle according to its status:

 *    'READY': Immediately return the datasetId and a promise that immediately resolves
 *    'ERROR': Return an error to indicate that the dataset is not viable
 *    'PROCESSING': Return the dataset ID and a promise that resolves when the pusher 'PROCESSED' event is received
 *    'UNINITIALISED': Upload the dataset file and return the dataset ID and a promise that resolves when the pusher 'PROCESSED' event is received
 *
 * @returns Promise<{ datasetId: string, readyPromise: Promise<void> }>
 */
export const handleDraftDataset = async ({
  dispatch,
  existingId,
  file,
}: {
  dispatch: Dispatch
  existingId: string | null
  file: File | null
}): Promise<
  { datasetId: string; readyPromise: Promise<void> } | { error: string }
> => {
  // if we don't have a dataset ID then try and create and upload one
  if (!existingId) {
    if (!file) {
      return { error: 'No dataset file for upload' }
    }

    const datasetId = await dispatch('createDataset', {
      name: file.name.split('.csv')[0],
    })
    await dispatch('uploadDataset', { datasetId, file })

    return {
      datasetId,
      readyPromise: getDatasetPusherSubscription(datasetId),
    }
  }

  try {
    const dataset = await http.get<{ status: Dataset['status'] }>(
      endpoints.DCTOOL_DATASET_STATUS(existingId)
    )

    const datasetId = existingId

    switch (dataset.status) {
      case 'READY': {
        return {
          datasetId,
          readyPromise: Promise.resolve(),
        }
      }
      case 'ERROR': {
        return {
          error: `Dataset failed processing. Upload a new dataset and try again.`,
        }
      }
      case 'PROCESSING': {
        return {
          datasetId,
          readyPromise: getDatasetPusherSubscription(datasetId),
        }
      }
      case 'UNINITIALISED': {
        await dispatch('uploadDataset', {
          datasetId,
          file,
        })

        return {
          datasetId,
          readyPromise: getDatasetPusherSubscription(datasetId),
        }
      }
    }
  } catch (getDatasetError) {
    if (isAxiosError(getDatasetError)) {
      return {
        error: `Request for dataset ${existingId} failed with status code ${getDatasetError.code}.`,
      }
    }

    return {
      error: 'An unknown error occurred.',
    }
  }
}
