import axios, { type AxiosInstance, type AxiosRequestConfig } from 'axios'
import configureInterceptors from './interceptors'

declare global {
  interface Window {
    axios?: typeof http
  }
}

// There is a response interceptor which returns `response.data` rather than the
// raw axios response. So we need to re-type `http` to account for that.
interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  useGlobalErrorHandling?: boolean
  useGlobal429ErrorHandling?: boolean
}
export interface HTTPWithInterceptors extends AxiosInstance {
  request<ResponseType = unknown>(
    config: CustomAxiosRequestConfig
  ): Promise<ResponseType>
  get<ResponseType = unknown>(
    url: string,
    config?: CustomAxiosRequestConfig
  ): Promise<ResponseType>
  delete<ResponseType = unknown, DataType = unknown>(
    url: string,
    data?: DataType,
    config?: CustomAxiosRequestConfig
  ): Promise<ResponseType>
  head<ResponseType = unknown>(
    url: string,
    config?: CustomAxiosRequestConfig
  ): Promise<ResponseType>
  options<ResponseType = unknown>(
    url: string,
    config?: CustomAxiosRequestConfig
  ): Promise<ResponseType>
  post<ResponseType = unknown, DataType = unknown>(
    url: string,
    data?: DataType,
    config?: CustomAxiosRequestConfig
  ): Promise<ResponseType>
  put<ResponseType = unknown, DataType = unknown>(
    url: string,
    data?: DataType,
    config?: CustomAxiosRequestConfig
  ): Promise<ResponseType>
  patch<ResponseType = unknown, DataType = unknown>(
    url: string,
    data?: DataType,
    config?: CustomAxiosRequestConfig
  ): Promise<ResponseType>
  postForm<ResponseType = unknown, DataType = unknown>(
    url: string,
    data?: DataType,
    config?: CustomAxiosRequestConfig
  ): Promise<ResponseType>
  putForm<ResponseType = unknown, DataType = unknown>(
    url: string,
    data?: DataType,
    config?: CustomAxiosRequestConfig
  ): Promise<ResponseType>
  patchForm<ResponseType = unknown, DataType = unknown>(
    url: string,
    data?: DataType,
    config?: CustomAxiosRequestConfig
  ): Promise<ResponseType>
}

const TIMEOUT = 90000 // 90 seconds
const axiosConfig = {
  baseURL:
    import.meta.env['VUE_APP_API_PROXY_PATH'] ||
    import.meta.env['VUE_APP_API_ROOT'],
  timeout: TIMEOUT,
}

// use axios with error handling
export const http = axios.create(axiosConfig) as HTTPWithInterceptors

configureInterceptors(http)

/**
 * Set default header authorization for axios instances
 * @param {string} token
 */
export function setDefaultHeaderAuthorization(token: string) {
  http.defaults.headers.common.Authorization = `Bearer ${token}`
}

export const isHttpClientError = (
  error: unknown
): error is { status: number; [key: string]: unknown } => {
  if (
    error &&
    typeof error === 'object' &&
    Object.prototype.hasOwnProperty.call(error, 'status')
  ) {
    return true
  }

  return false
}

// expose axios for e2e test hooks
if (import.meta.env['VUE_APP_MODE'] === 'e2e') {
  window.axios = http
}

export default http
