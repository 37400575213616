import { WebStorageStateStore } from 'oidc-client-ts'
import { vuexOidcCreateStoreModule } from 'vuex-oidc'
import { setDefaultHeaderAuthorization } from '@/api/http'
import { translate } from '@/i18n'
import rollbar from '@/integrations/rollbar'
import { clearAdaMetaData } from '@/utils/ada'
import logger from '@/utils/logger'
import notifier from '@/utils/notifier'

const config = {
  isAuthenticatedBy: 'access_token',
  // response type code is for Authorization Code Flow with PKCE
  responseType: 'code',
  // offline_access is required for rotating refresh tokens
  scope: 'openid profile offline_access',
  // NOTE: with rotating refresh tokens and a low token lifetime this is fine to store in local storage
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  authority: import.meta.env.VUE_APP_AUTH0_AUTHORITY,
  clientId: import.meta.env.VUE_APP_AUTH0_CLIENT_ID,
  redirectUri: import.meta.env.VUE_APP_AUTH0_REDIRECT_URI,
  postLogoutRedirectUri: import.meta.env.VUE_APP_AUTH0_POST_LOGOUT_REDIRECT_URI,
  automaticSilentRenew: true,
  extraQueryParams: {
    returnPath: window.location.pathname + window.location.search,
    audience: 'https://internal-api.prolific.com',
  },
  metadata: {
    authorization_endpoint: `${
      import.meta.env.VUE_APP_AUTH0_AUTHORITY
    }/authorize`,
    userinfo_endpoint: `${import.meta.env.VUE_APP_AUTH0_AUTHORITY}/userinfo`,
    issuer: `${import.meta.env.VUE_APP_AUTH0_AUTHORITY}/`,
    jwks_uri: `${
      import.meta.env.VUE_APP_AUTH0_AUTHORITY
    }/.well-known/jwks.json`,
    end_session_endpoint:
      import.meta.env.VUE_APP_AUTH0_END_SESSION_ENDPOINT ??
      `${import.meta.env.VUE_APP_AUTH0_AUTHORITY}/v2/logout?client_id=${
        import.meta.env.VUE_APP_AUTH0_CLIENT_ID
      }&returnTo=${import.meta.env.VUE_APP_AUTH0_POST_LOGOUT_REDIRECT_URI}`,
    token_endpoint: `${import.meta.env.VUE_APP_AUTH0_AUTHORITY}/oauth/token`,
  },
}

let oidcStore = {
  state: {},
}

if (import.meta.env.VUE_APP_MODE !== 'test') {
  oidcStore = vuexOidcCreateStoreModule(
    config,
    // NOTE: If you do not want to use localStorage for tokens, in stead of just passing oidcSettings, you can
    // spread your oidcSettings and define a userStore of your choice
    // {
    //   ...oidcSettings,
    //   userStore: new WebStorageStateStore({ store: window.sessionStorage })
    // },
    // Optional OIDC store settings
    {
      namespaced: true,
      dispatchEventsOnWindow: true,
    },
    // Optional OIDC event listeners
    {
      userLoaded: user => {
        logger.log('OIDC user is loaded:', user)
        setDefaultHeaderAuthorization(user.access_token)
      },
      userUnloaded: () => {
        clearAdaMetaData()
        logger.log('OIDC user is unloaded')
      },
      accessTokenExpiring: () => logger.log('Access token will expire'),
      accessTokenExpired: () => {
        clearAdaMetaData()
        logger.log('Access token did expire')
      },
      silentRenewError: () => logger.log('OIDC user is unloaded'),
      userSignedOut: () => {
        clearAdaMetaData()
        logger.log('OIDC user is signed out')
      },
      oidcError: err => {
        clearAdaMetaData()
        logger.log('OIDC error: ', err)
        if (
          err &&
          err.error &&
          err.error.match(/(exp is in the past)|(iat is in the future)/)
        ) {
          // clock skew error
          notifier.error(translate('errors.oauth_clock_skew'), {
            duration: 0,
          })
        }
        // note err here is from oidc-client and is a non-native
        // error message, so we're not going to get stacktraces at
        // Rollbar. Instead we can group by `err.context` message.
        rollbar.error(err.context, err)
      },
    }
  )
}

export default oidcStore
